import React from "react"
import styled from "@emotion/styled"
import MakerArray from './datas/makerAll';
import MakerRankTable from './makerRankTable';
import MakerRankDescription from './makerRankDescriptionLoadable';

const SelectBox = styled.div({
  input: {
    display: 'none',
  },
  'input:checked + label': {
    color: '#fff',
    backgroundColor: '#25A298',
    borderColor: 'black',
    fontWeight: 'bold',
  },
  label: {
    display: 'inline-block',
    fontSize: '1.5rem',
    lineHeight: '1.5',
    margin: '5px 2px 5px 0px',
    padding: '0.3em 1em',
    backgroundColor: '#eee',
    color: '#444',
    borderRadius: '5px',
    cursor: 'pointer',
  }
});

export default class MakerRanking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radios: {
        kind: "na",
        note: "na",
        desktop: "na",
      },
      checkboxes: {
        cospa: false,
        design: false,
        support: false,
        business: false,
        light: false,
      }
    };
    // 人気順に並び変える
    // ラジオボタンの初期値をセット
  }

  handleChange = e => {
    const { name, value, checked } = e.target;
    if (['kind', 'note', 'desktop'].includes(name)) {
      this.setState({
        radios: {
          ...this.state.radios,
          [name]: value,
        }
      });
    } else {
      this.setState({
        checkboxes: {
          ...this.state.checkboxes,
          [name]: checked
        }
      })
    }
  }

  createMakerArray = () => {
    const { kind, note, desktop } = this.state.radios;
    const { cospa, design, support, business, light} = this.state.checkboxes;
    let makerArray = MakerArray.filter((maker) => {
      if (kind === 'desktop') {
        switch (desktop) {
          case 'na':
            return maker.hasDesktop;
          case 'allinone':
            return maker.hasAllInOne;
          case 'slim':
            return maker.hasDesktop && !maker.hasNoSlim;
          case 'tower':
            return maker.hasTower;
          default:
            break;
        }
      }
      if (kind === 'note') {
        if (!maker.hasNote) {
          return false;
        }
        switch (note) {
          case 'na':
            return maker.hasNote;
          case 'standard':
            return maker.hasStandard !== false;
          case 'mobile':
            return maker.hasMobile;
          case 'gaming':
            return maker.hasGamingNote;
          default:
            break;
        }
      }
      return true;
    });
    makerArray.sort((makerA, makerB) => {
      let score = 0;
      score += cospa ? makerB.cospa - makerA.cospa : 0;
      score += design ? makerB.design - makerA.design : 0;
      score += support ? makerB.support - makerA.support : 0;
      if (kind === 'note' && note === 'mobile') {
          if (light) {
              score += makerB.mobileBonus - makerA.mobileBonus;
          }
      }
      if (kind === 'desktop' && desktop === 'tower') {
          score += makerB.towerBonus - makerA.towerBonus;
      }
      if (kind === 'note' && note === 'gamingnote') {
          score += makerB.gamingnoteBonus - makerA.gamingnoteBonus;
      }
      score += business ? makerB.businessBonus - makerA.businessBonus : 0;
      if (score === 0) {
          score = makerB.popular - makerA.popular;
      }
      return score;
    });
    return makerArray;
  }

  render() {
    const { kind, note, desktop } = this.state.radios;
    const { cospa, design, support, business, light} = this.state.checkboxes;
    const { showDescription, highlight } = this.props;
    const makerArray = this.createMakerArray();
    return (
      <>
        <SelectBox className="mobile-margin">
          <div>
            種類：
            <input name="kind" type="radio" id="nakind" value="na" checked={kind === "na"} onChange={this.handleChange}/>
            <label htmlFor="nakind">未定</label>
            <input name="kind" type="radio" id="note" value="note" checked={kind === "note"} onChange={this.handleChange}/>
            <label htmlFor="note">ノート</label>
            <input name="kind" type="radio" id="desktop" value="desktop" checked={kind === "desktop"} onChange={this.handleChange}/>
            <label htmlFor="desktop">デスクトップ</label>
          </div>
          {kind === "note" && <div id="noteDetail">
            詳細：
            <input name="note" type="radio" id="notena" value="na" checked={note === "na"} onChange={this.handleChange}/>
            <label htmlFor="notena">未定</label>
            <input name="note" type="radio" id="mobile" value="mobile" checked={note === "mobile"} onChange={this.handleChange}/> 
            <label htmlFor="mobile">モバイル(~13.5)</label>
            <input name="note" type="radio" id="standard" value="standard" checked={note === "standard"} onChange={this.handleChange}/>
            <label htmlFor="standard">スタンダード(14~)</label>
            <input name="note" type="radio" id="gaming" value="gaming" checked={note === "gaming"} onChange={this.handleChange} />
            <label htmlFor="gaming">ゲーミング</label>
          </div>}
          {kind === "desktop" && <div id="desktopDetail">
            詳細：
            <input name="desktop" type="radio" id="desktopna" value="na" checked={desktop === "na"} onChange={this.handleChange}/>
            <label htmlFor="desktopna">未定</label>
            <input name="desktop" type="radio" id="allinone" value="allinone" checked={desktop === "allinone"} onChange={this.handleChange}/>
            <label htmlFor="allinone">一体型</label>
            <input name="desktop" type="radio" id="slim" value="slim" checked={desktop === "slim"} onChange={this.handleChange}/>
            <label htmlFor="slim">スリム型</label>
            <input name="desktop" type="radio" id="tower" value="tower" checked={desktop === "tower"} onChange={this.handleChange} />
            <label htmlFor="tower">タワー型</label>
          </div>}
          <div>
            こだわり：
            <input name="cospa" id="cospa" type="checkbox" checked={cospa} onChange={this.handleChange}/>
            <label htmlFor="cospa">コスパ</label>
            <input name="design" id="design" type="checkbox" checked={design} onChange={this.handleChange}/>
            <label htmlFor="design">デザイン</label>
            <input name="support" id="support" type="checkbox" checked={support} onChange={this.handleChange}/>
            <label htmlFor="support">サポート</label>
            <input name="business" id="business" type="checkbox" checked={business} onChange={this.handleChange}/>
            <label htmlFor="business">ビジネス</label>
            <input name="light" id="light" type="checkbox" checked={light} onChange={this.handleChange}/>
            <label htmlFor="light">軽量</label> 
          </div>
        </SelectBox>
        <MakerRankTable makerArray={makerArray} highlight={highlight} />
        {showDescription && <MakerRankDescription {...this.props} makerArray={makerArray}/>}
      </>
    )
  }
}