import React from "react"
import ScrollTable from './scrollTable';
import { color } from "../utils/style";
import { navigate } from '@reach/router';

export default class MakerRanking extends React.Component {
  render() {
    const { makerArray, highlight } = this.props;
    return (
      <ScrollTable>
          <thead>
            <tr>
              <th>メーカー</th>
              <th>コスパ</th>
              <th>デザイン</th>
              <th>サポート</th>
              <th>人気</th>
              <th>特徴</th>
            </tr>
          </thead>
          <tbody>
          {makerArray.map((maker, i) => {
            return (
              <tr key={maker.name} className={highlight === maker.name && "highlight"}>
                <td align="left"><span style={{color: color.main, cursor: 'pointer'}}onClick={() => navigate(`#${maker.name}`)}>{i+1}位:</span><span dangerouslySetInnerHTML={{__html: maker.url}} /></td>
                <td>{maker.cospa}</td>
                <td>{maker.design}</td>
                <td>{maker.support}</td>
                <td>{maker.popular}</td>
                <td align="left">{maker.description}</td>
              </tr>
            )
          })}
          </tbody>
      </ScrollTable>
    )
  }
}